<template>
 <div class="v_money_detail g-flex-column">
   <head-back :isBack="true">
    <template>
      <span>我的业务员</span>
    </template>
  </head-back>
  <div class="v-money-content">
    <van-list class="v-money-content-list" v-show="ywyList.length!=0" v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad" :immediate-check="false">
      <div class="v-money-content-list-item" v-for="(item,index) in ywyList" :key="index">
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">业务员ID: </span>
          <span class="v-money-content-list-item-line-value">{{item.id}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">业务员姓名:</span>
          <span class="v-money-content-list-item-line-value">{{item.name}}</span>
        </div>
         <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">业务员手机号:</span>
          <span class="v-money-content-list-item-line-value">{{item.mobile}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">抽佣点数：</span>
          <span class="v-money-content-list-item-line-value">{{item.reach_rate}}%</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">上级业务员姓名: </span>
          <span class="v-money-content-list-item-line-value">{{item.spread.name}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">上级业务员ID: </span>
          <span class="v-money-content-list-item-line-value">{{item.spread.id}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">上级业务员手机号: </span>
          <span class="v-money-content-list-item-line-value">{{item.spread.mobile}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">添加时间:</span>
          <span class="v-money-content-list-item-line-value">{{formatDate(item.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">推广所得利润:</span>
          <span class="v-money-content-list-item-line-value v-money-content-list-item-line-value-balance">{{item.money}}元</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">推广商家数量:</span>
          <span class="v-money-content-list-item-line-value v-money-content-list-item-line-value-order-total">{{item.shop_nums}}个</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">下级代理个数:</span>
          <span class="v-money-content-list-item-line-value v-money-content-list-item-line-value-order-total">{{item.agent_nums}}个</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center" v-if="item.xj">
           <van-button type="info" size="small" @click="seeBottomAgent(item.id)">查看下级</van-button>
        </div>

      </div>
    </van-list>
    <NoList v-show="ywyList.length == 0"/> 

  </div>
 </div>
</template>

<script>
import HeadBack from '../../components/HeadBack.vue'
import { PAGE, LIMIT } from '@/utils/constants.js'
import { formatDate } from '@/utils/index.js'
import { apiGetSpreadList } from '@/utils/api.js'
export default {
  components: { HeadBack },
  data() {
    return {
      show: false,
      minDate: new Date(2004, 0, 1),
      maxDate: new Date(),
      num: 10,
      ywyList: [],
      form: {
        page: PAGE,
        limit: LIMIT,
        user_id: this.$route.params.id
      },
      loading: false,
      finished: false
    }
  },
  created() {
    this.apiGetSpreadListHandel()
  },
  methods: {
    formatDate,
    seeBottomAgent(id) {
      this.$router.push({ name: 'myywysecond', params: { id: id }}).catch(() => {})
    },

    // 获取推广列表
    async apiGetSpreadListHandel() {
      this.$global.loadingShow = true
      const { success, data } = await apiGetSpreadList(this.form)
      if(!success) return
      this.ywyList = this.ywyList.concat(data.list)
      this.finished = data.list.length < this.form.limit
      console.log(data)
    },

    onLoad() {
      this.form.page++
      this.apiGetSpreadListHandel()
    }
  },
}
</script>

<style lang='scss'>
.v_money_detail {
  height: 100%;
  overflow: auto;
  .iconrili {
    font-size: 20px;
    color: $main_color;
    height: 100%;
    padding: 0 0 0 8px;
  }
  .v-money-content {
    flex: 1;
    overflow: auto;
    background: #F1F1F1;
    .v-money-content-list {
      height: 100%;
      padding: 8px 16px;
      background: #F1F1F1;
      overflow: auto;
      .v-money-content-list-item {
        background: $white;
        margin-top: 14px;
        border-radius: 8px;
        padding: 20px 18px;
        &:nth-of-type(1) {
          margin-top: 0;
        }
        .v-money-content-list-item-line {
          padding-top: 18px;
          font-size: 13px;
          color: #0B0B0B;
          &:nth-of-type(1) {
            padding-top: 0;
          }
          .v-money-content-list-item-line-title {
            min-width: 40px;
          }
          .v-money-content-list-item-line-value {
            flex: 1;
            padding-left: 10px;
            word-break: break-all;
            &.v-money-content-list-item-line-value-money {
              color: #2473EF;
              font-size: 18px;
              font-weight: bold;
            }
            &.v-money-content-list-item-line-value-balance {
              color: #FF6A00;
              font-size: 18px;
              font-weight: bold;
            }
            &.v-money-content-list-item-line-value-order-total {
              color: #2473EF;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>